<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.model" @change="Search" placeholder="产品型号"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <!-- <el-table-column label="记录编码">
        <template v-slot="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品型号">
        <template v-slot="scope">
          <span>{{ scope.row.model }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="产品编号">
        <template v-slot="scope">
          <span>{{ scope.row.client_product_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="型号秘钥">
        <template v-slot="scope">
          <span>{{ scope.row.secret }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" width="450px">
        <template v-slot="scope">
          <input type="checkbox" :ref="'check'+scope.row.code" @change="checkchange(scope.row)">
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        model: '',
        client_product_code: '',
      },
      list: [],
      total: 0,
      prolist: [],
      proCode: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search() {
      this.api.ProductModelList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    checkchange (item) {
      this.api.Productconfig({
        client_product_code: this.proCode,
        code: item.code,
        status: 1
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('分配成功')
          this.Search()
          return
        }
        this.$message.error('分配失败' + res.data.msg)
      })
    },
    goback () {
      this.$router.push('/iot/promodel')
    }
  },
  filters: {},
  mounted () {
    this.proCode = this.$route.params.code
    this.Search()
  },
  created () {
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
